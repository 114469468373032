import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout"
import Seo from "../components/seo"
import SliderLaden from "../components/slider/laden"
import imgTitleLaden from "../images/page_title_laden.png"

const Laden = props => {
  return (
    <Layout>
      <Seo title="ladengeschäft im zuckerhut hildesheim" />
      <SliderLaden />
      <section className="laden container">        
        <img
          className="page-title"
          src={ imgTitleLaden }
          alt="unser laden im zuckerhut"
        />       
        <p>
          unser kleines ladengeschäft befindet sich im erdgeschoss eines kleinen
          fachwerkhauses – dem „zuckerhut“. hier verkaufen wir alle unsere
          selbstgerösteten frischen kaffees, ausgewählte tees, handgeschöpfte
          schokoladen, zubehör rund um kaffee und vieles mehr.
        </p>
        <p className="content-center">
          <strong>ladengeschäft im zuckerhut</strong>
          <br />
          andreasplatz 20 · 31134 hildesheim <br />
          telefon 05121 98910-41
          <br />
          info@daskleineroestwerk.de        
        </p>        
        <div className="page-imagebox">
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image1.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image2.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image3.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>      
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image4.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>      
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image5.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>      
          <div className="page-imagebox-image">
            <GatsbyImage
              image={props.data.page_image6.childImageSharp.gatsbyImageData}
              alt="unser laden im zuckerhut" />
          </div>      
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`{
  page_image1: file(relativePath: {eq: "page_laden-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  page_image2: file(relativePath: {eq: "page_laden-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  page_image3: file(relativePath: {eq: "page_laden-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  page_image4: file(relativePath: {eq: "page_laden-4.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  page_image5: file(relativePath: {eq: "page_laden-5.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
  page_image6: file(relativePath: {eq: "page_laden-6.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 500, layout: CONSTRAINED)
    }
  }
}
`

export default Laden
