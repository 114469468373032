import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import { Fade } from 'react-slideshow-image'

import 'react-slideshow-image/dist/styles.css'
import "./_slider.scss"

const fadeProperties = {
  duration: 3000,
  transitionDuration: 1000,
  infinite: true,
  indicators: false,
  arrows: false,
  onChange: (oldIndex, newIndex) => {
    console.log(`fade transition from ${oldIndex} to ${newIndex}`)
  },
}

const SliderLaden = () => {
  const data = useStaticQuery(graphql`{
  slide1: file(relativePath: {eq: "slide_laden-1.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide2: file(relativePath: {eq: "slide_laden-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide3: file(relativePath: {eq: "slide_laden-3.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide4: file(relativePath: {eq: "slide_laden-4.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide5: file(relativePath: {eq: "slide_laden-5.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide6: file(relativePath: {eq: "slide_laden-6.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide7: file(relativePath: {eq: "slide_laden-7.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide8: file(relativePath: {eq: "slide_laden-8.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide9: file(relativePath: {eq: "slide_laden-9.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide10: file(relativePath: {eq: "slide_laden-10.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  slide11: file(relativePath: {eq: "slide_laden-11.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <div className="slide-container">
      <Fade {...fadeProperties}>
      <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide1.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide2.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide3.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide4.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide5.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide6.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide7.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide8.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide9.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide10.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
        <div className="each-fade">
          <div className="image-container">
          <GatsbyImage image={data.slide11.childImageSharp.gatsbyImageData} alt="test" />
          </div>
        </div>        
      </Fade>
    </div>
  );
}

export default SliderLaden
